<template>
  <div>
    <div class="mainbox">
      <div class="error-container">
        <div class="err">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm16.32-4.9L5.09 16.31A8 8 0 0 0 16.32 5.09zm-1.41-1.42A8 8 0 0 0 3.68 14.91L14.91 3.68z"
            />
          </svg>
          {{ errorCode }}
        </div>
        <div class="msg" v-html="msg"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorCode: {
      type: String,
      default: "404",
    },
    msg: {
      type: String,
      default: `Maybe this page moved? Got deleted?
        <p>
          Let's go
          <a href="/">home</a> and try
          from there.
        </p>`,
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  margin: auto;
  height: 400px;
  width: 600px;
  position: relative;
  .error-container {
    background: #fff;
    padding: 20px;
    height: 330px;
    margin: 40px;
    box-shadow: rgba(0, 0, 0, 0.19) 0 0 8px 0;
  }
  .err {
    color: #cf0404;
    font-size: 11rem;
    position: absolute;
    left: 20%;
    display: flex;
    svg {
      width: 80px;
      fill: #cf0404;
      margin-right: 30px;
      margin-top: 20px;
    }
  }
  .msg {
    text-align: center;
    font-size: 1.3rem;
    position: absolute;
    top: 50%;
    width: 75%;

    a {
      text-decoration: none;
      color: #1565c0;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
