var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mainbox" }, [
      _c("div", { staticClass: "error-container" }, [
        _c("div", { staticClass: "err" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 20 20"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm16.32-4.9L5.09 16.31A8 8 0 0 0 16.32 5.09zm-1.41-1.42A8 8 0 0 0 3.68 14.91L14.91 3.68z"
                }
              })
            ]
          ),
          _vm._v(" " + _vm._s(_vm.errorCode) + " ")
        ]),
        _c("div", {
          staticClass: "msg",
          domProps: { innerHTML: _vm._s(_vm.msg) }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }